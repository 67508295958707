<script lang="tsx" setup>
defineOptions({
  inheritAttrs: false
})

const props = defineProps<{
  title: string
  subtitle?: string
  wrapperClass?: string
  headerClass?: string
}>()

const slots = defineSlots<{
  default: () => any
  subtitle?: () => any
  header?: () => any
}>()

const id = props.title.toLowerCase().split(' ').join('-')
const hashLink = `#${id}`

function Header(_: any, ctx: any) {
  const slot = ctx.slots.default()
  const className = props.headerClass

  return (className || slots.header)
    ? (
        <header class={className}>
          <div>{slot}</div>
          {slots.header?.()}
        </header>
      )
    : slot
}

onMounted(() => {
  // Scroll to section
  if (location.hash === hashLink)
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'start' })
})
</script>

<template>
  <section :id class="$max-width-565px w-full max-w-$max-width" :class="wrapperClass">
    <Header>
      <a :href="hashLink">
        <h3 class="text-heading-5 text-neutral-700 <sm:text-base">{{ title }}</h3>
      </a>

      <p v-if="subtitle || $slots.subtitle" class="mt-3 text-lg text-neutral-500 <sm:text-base">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </p>
    </Header>

    <div class="mt-6 p-4 gap-6 sm:p-9 sm:gap-9 grid rounded-2.5 bg-shade-light" v-bind="$attrs">
      <slot />
    </div>
  </section>
</template>
